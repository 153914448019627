<template>
  <div class="topbar">
    <div class="logoContainer">
      <router-link to="/">
        <div class="logo">
          <img src="@/assets/logo.png" alt="Coffee and Company" />
        </div>
      </router-link>
    </div>
    <i v-show="!showMenu" class="fas fa-bars menu" @click="$emit('toggle-menu')" />
    <i v-show="showMenu" class="fas fa-times menu" @click="$emit('toggle-menu')" />
  </div>
  <FlyOutMenu v-show="showMenu" @toggle-flyout="$emit('toggle-menu')" />
</template>

<script>
import FlyOutMenu from "./FlyOutMenu";
export default {
  name: "TopBar",
  components: {
    FlyOutMenu
  },
  props: {
    showMenu: Boolean
  }
};
</script>

<style scoped lang="scss">
 .topbar {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: calc(100vw - 36px);
   height: 48px;
   background-color: $dark;
   color: $primary;
   padding: 2px 24px 2px 12px;
   position: fixed;
   top: 0;
   left: 0;
   .logo {
     width: 38px;
     height: 38px;
     border-radius: 50%;
     padding: 2px;
     background-color: $light;
     img {
       width: 100%;
       height: 100%;
     }
   }
 }
</style>