import axios from "axios";

export const API_HOST = 'https://api.coffeeandcompany.de/api'
export const API_STORE_ORDER = '/order';
export const API_GET_INFO = '/news';

export const APP_PAGE = (url) => {
	if (url.includes('rsg')) {
		return 'rsg_cham';
	} else if (url.includes('jvfg')) {
		return 'jvfg_cham';
	} else if (url.includes('crown')) {
		return 'crown'
	} else {
		return 'jvfg_cham';
	}
}

export const infoRequest = axios.get(API_HOST + API_GET_INFO);
export const menuRequest = (calendarWeek) => axios.get(`${API_HOST}/print-menus?start=${calendarWeek}&end=${calendarWeek}&location=${APP_PAGE(window.location.href)}`);
// export const menuRequestNextWeek = axios.get(`${API_HOST}/print-menus?start=${calendarWeek+1}&end=${calendarWeek+1}&location=${APP_PAGE(window.location.href)}`);

export const daysGerman = {
	Monday: 'Montag',
	Tuesday: 'Dienstag',
	Wednesday: 'Mittwoch',
	Thursday: 'Donnerstag',
	Friday: 'Freitag'
}

export const locationNames = {
	rsg_cham: 'Robert-Schuman-Gymnasium',
	jvfg_cham: 'Joseph-von-Fraunhofer-Gymnasium',
	kantine10: 'Kantine 10',
	crown: 'Kantine Crown',
	gms: 'GMS Roding',
	test: 'Test Location'
}

export const orderTime = {
	rsg_cham: 9,
	jvfg_cham: 9,
	kantine10: 9,
	crown: 9,
	gms: 7,
	test: 7
}
