<template>
<div class="header">
	<div class="headlineBox">
		<h1>{{ location }}</h1>
		<h2>Wochenplan für KW {{calendarWeek}}</h2>
		<h3>{{firstDay}} bis {{lastDay}}</h3>
	</div>
	<div class="headlineButton">
		<Button
						:btn-color="isThisWeek ? '#C59117' : '#ffca57'"
						:btn-label="isThisWeek ? 'Menü nächste Woche' : 'Menü aktuelle Woche'"
						@click="isThisWeek ? $router.push('/next') : $router.push('/')"/>
	</div>
</div>
</template>

<script>
import {locationNames, APP_PAGE} from "../helper/contstants";
import Button                    from "./Button";

export default {
  name: "Header",
	components: {Button},
	props: {
    calendarWeek: Number,
    firstDay: String,
    lastDay: String,
		isThisWeek: Boolean
  },
	data() {
  	return {
  		location: locationNames[APP_PAGE(window.location.href)]
		}
	}
};
</script>

<style scoped lang="scss">
.header {
	display: flex;
	align-items: center;
	.headlineBox {
		flex-grow: 1;
		h1 {
			font-size: 24px;
			color: $dark;
		}
		h2 {
			font-size: 18px;
			color: $dark;
			margin: 0;
		}
		h3 {
			font-size: 16px;
			color: $primary;
			margin: 0;
		}
  }
}
</style>
